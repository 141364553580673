<template>
  <div class="ml-2">
    <a href="javascript:void(0);" @click="clearSelectedTest()">
      <v-icon
              color="red"
              @click="clearSelectedTest()"
              title="Stop Selected"
              style="font-size: 30px;"
      >mdi-stop</v-icon><span style="position: relative;top:1px;">{{language.componentLabel.titleStopSelected}}</span>
    </a>
  </div>
</template>
<script>
import {clearTest} from "@/js/testHelpers.js";
export default {
  methods: {
    clearSelectedTest() {
        //console.log(response);
  let currentTable = this.table;
      for (let [index, value] of Object.entries(this.selectedRows)) {
        clearTest(index, value,currentTable,this);
      }
    },
    
  },
  props: ["selectedRows", "table"],
};

// function
</script>